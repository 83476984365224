<template>
  <div class="text-center mt-8">
    <v-row>
      <v-col class="col-12">
        <h1>Permissões de Usuario</h1>
      </v-col>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="desserts"
        :single-select="singleSelect"
        item-key="name"
        show-select
        class="elevation-1"
      >
        <template v-slot:top>
          <v-switch
            v-model="singleSelect"
            label="Single select"
            class="pa-3"
          ></v-switch>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import instanceAxios from "../../plugins/axios";
export default {
  data() {
    return {
      roles: [],
      permissions: [],
    };
  },
  methods: {
    getRoles() {
      const { data } = instanceAxios.get("roles");
      this.roles = data;
    },
    getPermissions() {
      const { data } = instanceAxios.get("roles");
      this.roles = data;
    },
  },
};
</script>

<style></style>
